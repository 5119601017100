import userAgent from '../Utils/userAgent';

const { useSelector, useDispatch } = require('react-redux');
const { useEffect } = require('react');
const { helperState, setUnityVersion } = require('../Store/reducers/helperReducer');

const useUnity = (force = false) => {
    const { unityVersion } = useSelector(helperState);
    const dispatch = useDispatch();

    const setUnityBottom = () => {
        if (typeof window !== 'undefined' && !document.getElementById('unity-version')) {
            const btn = document.createElement('button');
            btn.style.position = 'fixed';
            btn.style.top = '0';
            btn.style.display = 'none';
            btn.id = 'unity-version';
            btn.value = unityVersion;
            btn.onclick = () => {
                dispatch(setUnityVersion(btn.value));
            };

            document.body.appendChild(btn);
        }
    };

    const fetchUnityVersion = () => {
        if (typeof window !== 'undefined' && document.getElementById('unity-version') && userAgent.isMobile.Assemblr()) {
            const version = document.getElementById('unity-version')?.value;
            window.location = 'assemblredu://requestunityversion';
            setTimeout(() => dispatch(setUnityVersion(version)), 200);
        }
    };

    useEffect(() => {
        setUnityBottom();
        setTimeout(() => {
            if (!unityVersion || unityVersion === '0.0.0' || unityVersion === '0' || unityVersion === '' || force) {
                fetchUnityVersion();
            }
        }, 100);
    }, []);

    return unityVersion;
};

export function UnityCheck({ force = false }) {
   const version = useUnity(force);
   useEffect(() => {
         console.log('Unity version:', version);
    }, [version]);

    return null;
}

export default useUnity;
